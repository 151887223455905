import * as React from 'react'
import { useHistory } from 'react-router-dom';
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from 'baseui/header-navigation'
import { StyledLink } from 'baseui/link'
import { Button } from 'baseui/button'
import { useStyletron } from 'baseui'
import ArrowRight from 'baseui/icon/arrow-right'
import Search from 'baseui/icon/search'

import SignOut from './SignOut'
import * as ROUTES from '../constants/routes'

const NavLink = props => {
  return (
    <StyledLink
      style={{
        textDecoration: 'none',
      }}
      href={props.href}
    >
      {props.children}
    </StyledLink>
  )
}

export const Header = ({ authUser }) => (
  <React.Fragment>
    {authUser ? <HeaderAuth /> : <HeaderNonAuth />}
  </React.Fragment>
)

const StyledHeaderNavigation = props => (
  <HeaderNavigation
      overrides={{
        Root: {
          style: ({$theme}) => ({
            border: 'none',
            paddingRight: $theme.sizing.scale800,
            minHeight: $theme.sizing.scale800,
            font: $theme.typography.font700
          }),
        },
      }}
  >
    {props.children}
  </HeaderNavigation>
)

const SearchInput =  () => {
  let history = useHistory();
  const [css, theme] = useStyletron();
  return (
    <div
      onClick={() => history.push(ROUTES.SEARCH)} 
      className={css({
        cursor: 'grab',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.sizing.scale500,
      })}
    >
      <Search size="36px" />
    </div>
  );
}


export const HeaderAuth = () => (
  <StyledHeaderNavigation>
    <StyledNavigationList $align={ALIGN.left}>
      <StyledNavigationItem>
        <NavLink href={ROUTES.LANDING}>deetly</NavLink>
      </StyledNavigationItem>
    </StyledNavigationList>
    <StyledNavigationList $align={ALIGN.center} />
    <StyledNavigationList $align={ALIGN.right}>
      <StyledNavigationItem>
        <NavLink href={ROUTES.EXAMPLES}>Examples</NavLink>
      </StyledNavigationItem>
    </StyledNavigationList>
    <StyledNavigationList $align={ALIGN.right}>
      <StyledNavigationItem>
        <NavLink href={ROUTES.ACCOUNT}>Account</NavLink>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <NavLink href={ROUTES.ADMIN}>Admin</NavLink>
      </StyledNavigationItem>
      <StyledNavigationItem>
        <SignOut />
      </StyledNavigationItem>
    </StyledNavigationList>
  </StyledHeaderNavigation>
)

export const HeaderNonAuth = () => {
  let history = useHistory();

  return (
  <StyledHeaderNavigation>
    <StyledNavigationList $align={ALIGN.left}>
      <StyledNavigationItem>
        <NavLink href={ROUTES.LANDING}>deetly</NavLink>
      </StyledNavigationItem>
    </StyledNavigationList>
    <StyledNavigationList $align={ALIGN.center} />
    <StyledNavigationList $align={ALIGN.right}>
      <StyledNavigationItem>
        <SearchInput />
      </StyledNavigationItem>
    </StyledNavigationList>
    <StyledNavigationList $align={ALIGN.right}>
      <StyledNavigationItem>
        <Button onClick={() => history.push(ROUTES.SIGN_IN)} startEnhancer={() => <ArrowRight />} >Sign in</Button>
      </StyledNavigationItem>
    </StyledNavigationList>
  </StyledHeaderNavigation>
)
}

export default Header
