import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import { createTheme, lightThemePrimitives } from 'baseui'

import Home from './pages/Home.js'
import Landing from './pages/Landing.js'
import PageNotFound from './pages/PageNotFound.js'
import Posts from './pages/Posts'
import SinglePost from './pages/SinglePost'
import Header from './components/Header'
import HeaderBar from './components/HeaderBar'

import SignUpPage from './pages/SignUp'
import SignInPage from './pages/SignIn'
import PasswordResetPage from './pages/PasswordReset'
import AccountPage from './pages/Account'
import AdminPage from './pages/Admin'
import SpacePage from './pages/Space'
import SearchPage from './pages/SearchPage'

import * as ROUTES from './constants/routes'
import { withAuthentication } from './components/Session'

const engine = new Styletron()
const Theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "Open Sans",
  },
  {
    // add all the theme overrides here - under the hood it uses deep merge
    animation: {
      timing100: '0.50s',
    },
  }
)

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      authUser: null,
    }
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser ? this.setState({ authUser }) : this.setState({ authUser: null })
    })
  }
  componentWillUnmount() {
    this.listener()
  }

  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={Theme}>
          <Router>
{/*             <HeaderBar authUser={this.state.authUser} /> */}
            <Header authUser={this.state.authUser} />
            <Switch>
              <Route exact path={ROUTES.LANDING} component={Landing} />
              <Route exact path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.SEARCH} component={SearchPage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordResetPage}
              />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route path={ROUTES.SPACE} component={SpacePage} />
              <Route path="/blog" exact component={Posts} />
              <Route path="/blog/:id" component={SinglePost} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </BaseProvider>
      </StyletronProvider>
    )
  }
}

export default withAuthentication(App)
