import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { DisplaySmall, ParagraphSmall } from 'baseui/typography'
import InputText from '../components/InputText'
import InputPassword from '../components/InputPassword'
import InputEmail from '../components/InputEmail'

import { withFirebase } from '../components/Firebase'
import * as ROUTES from '../constants/routes'

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  space: '',
  error: null,
}

const SignUpPage = () => (
  <Block
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Block flex="1" maxWidth="400px" marginTop="scale1200">
      <DisplaySmall>Create account</DisplaySmall>
      <Block marginTop="scale800">
        <SignUpForm />
      </Block>
    </Block>
  </Block>
)

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = event => {
    const { username, email, passwordOne, space } = this.state
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create user in Firebase realtime database
        return this.props.firebase.users().add({
          username,
          email,
          space,
        })
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.HOME)
      })
      .catch(error => {
        this.setState({ error })
      })
    event.preventDefault()
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      space,
      error,
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
      space === ''

    return (
      <form onSubmit={this.onSubmit}>
        <InputText
          id="id_name"
          name="username"
          label="Your name"
          placeholder="Full Name"
          value={username}
          onChange={this.onChange}
        />
        <InputEmail
          id="id_email"
          name="email"
          label="Your email"
          value={email}
          onChange={this.onChange}
          erroMessage="Please input a valid email address"
        />
        <InputPassword
          id="id_pwd_one"
          name="passwordOne"
          label="Password"
          value={passwordOne}
          onChange={this.onChange}
        />
        <InputPassword
          id="id_pwd_two"
          name="passwordTwo"
          label="Repeat Password"
          value={passwordTwo}
          onChange={this.onChange}
        />
        <InputText
          id="space"
          name="space"
          label="Space name"
          placeholder="Space"
          value={space}
          onChange={this.onChange}
        />
        <Button type="submit">Sign up</Button>
        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const SignUpLink = () => (
  <ParagraphSmall>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign up here</Link>
  </ParagraphSmall>
)

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase)

export default SignUpPage

export { SignUpForm, SignUpLink }
