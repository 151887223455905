import * as React from 'react'
import { styled } from 'styletron-react'
import SearchIcon from 'baseui/icon/search'

const Icon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  marginRight: '1em',
})

export const Search = () => (
  <Icon>
    <SearchIcon size="scale800" color="#aaa" />
  </Icon>
)

export default Search
