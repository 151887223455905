export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/home'
export const BLOG = '/blog'
export const EXAMPLES = '/examples'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/pw-forget'
export const SPACE = '/space'
export const SEARCH = '/search'
