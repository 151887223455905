import * as React from 'react'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { validate as validateEmail } from 'email-validator' // add this package to your repo with `$ yarn add email-validator`

import Negative from './Negative'

const InputEmail = ({
  id,
  label,
  name,
  inputValue = '',
  onChange,
  errorMessage,
}) => {
  const [value, setValue] = React.useState(inputValue)
  const [isValid, setIsValid] = React.useState(false)
  const [isVisited, setIsVisited] = React.useState(false)
  const shouldShowError = !isValid && isVisited
  const _onChange = event => {
    setIsValid(validateEmail(event.target.value))
    setValue(event.target.value)
    onChange(event)
  }

  return (
    <React.Fragment>
      <FormControl
        label={label}
        error={shouldShowError ? { errorMessage } : null}
      >
        <Input
          id={id}
          name={name}
          value={value}
          onChange={_onChange}
          onBlur={() => setIsVisited(true)}
          error={shouldShowError}
          overrides={shouldShowError ? { After: Negative } : {}}
          type="email"
          required
        />
      </FormControl>
    </React.Fragment>
  )
}

export default InputEmail
