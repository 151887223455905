import { useEffect, useState } from 'react'
import client from './client'

export function usePosts() {
  const [posts, setPosts] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    client
      .get('blogposts')
      .then(function(response) {
        setPosts(response.data)
        setLoading(false)
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [])

  return [posts, isLoading]
}

export default usePosts
