import React from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'baseui/spinner'
import { Card } from 'baseui/card'
import { Block } from 'baseui/block'

import { usePosts } from '../hooks'
import { formatDate } from '../components/utils'

export default function Posts() {
  const [posts, isLoading] = usePosts()

  const renderPosts = () => {
    if (isLoading) return <Spinner />

    return posts.map(post => {
      const url = './blog/' + post.fields.slug
      return (
        <Card
          headerImage={post.fields.heroImage.fields.file.url}
          title={<Link to={url}>{post.fields.heroImage.fields.title}</Link>}
          description={post.fields.description}
          overrides={{
            Root: {
              style: { maxWidth: '280px', justifySelf: 'center' },
            },
          }}
        >
          {formatDate(post.fields.publishDate)}
        </Card>
      )
    })
  }

  return (
    <React.Fragment>
      <Block display="flex" flexDirection="column">
        <Block
          flex={'1 0 auto'}
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(280px, 1fr))"
          justifyItems="center"
          gridGap="scale1000"
          margin="scale1000"
        >
          {renderPosts()}
        </Block>
      </Block>
    </React.Fragment>
  )
}
