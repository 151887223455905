import * as React from 'react'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'

const InputText = ({ id, label, name, inputValue = '', onChange }) => {
  const [value, setValue] = React.useState(inputValue)
  const [isVisited, setIsVisited] = React.useState(false)
  const _onChange = event => {
    setValue(event.target.value)
    onChange(event)
  }

  return (
    <React.Fragment>
      <FormControl label={label}>
        <Input
          id={id}
          name={name}
          value={value}
          onChange={_onChange}
          onBlur={() => setIsVisited(true)}
          type="input"
          required
        />
      </FormControl>
    </React.Fragment>
  )
}

export default InputText
