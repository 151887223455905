import React from 'react'
import { Block } from 'baseui/block'
import { HeadingSmall, ParagraphSmall } from 'baseui/typography'
import { useStyletron } from 'baseui'

const base_url = process.env.REACT_APP_VIEWER_URL

const Hit = ({ hit }) => {
  const [css, theme] = useStyletron();
  const space = hit.space || null
  let link = `${base_url}/${hit.id}`
  if (space) {
    link = `${base_url}/${hit.space}/${hit.id}`
  }
  const title = hit.title || hit.name || 'unknown'

  const date = new Date(hit.updated);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const localDate = date.toLocaleDateString('nb-NO', options)

  return (
    <Block  
            padding='scale500'
            width="100%" 
            backgroundColor="#efefef" 
            marginBottom='scale800'
      >
      {hit && (
        <Block>
          <a
            href={link}
            className={css({
                cursor: 'grab',
                color: theme.colors.Primary,
                textDecoration: 'None',
                ':hover': {color: theme.colors.accent400},
              })}
           >
           <HeadingSmall>{title}</HeadingSmall>
           <ParagraphSmall>{localDate}</ParagraphSmall>
           <ParagraphSmall>Space: {hit.space}</ParagraphSmall>
{/*            <ParagraphSmall>{JSON.stringify(hit)}</ParagraphSmall> */}
          </a>
        </Block>
      )}
    </Block>
  )
}

export const Hits = ({ hits }) => {
  return (
    <Block 
      marginRight='scale800'
    >
      {hits &&
        hits.map((hit, index) => {
          const title = hit.title || hit.name || 'unknown'
          return <Hit key={`hit_${index}`} hit={hit}></Hit>
        })}
    </Block>
  )
}

