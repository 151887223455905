import React, { Component } from 'react'
import { withFirebase } from '../../components/Firebase'
class AdminPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      users: [],
    }
  }
  componentDidMount() {
    this.setState({ loading: true })
    const usersList = []
    this.props.firebase
      .users()
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          usersList.push({ id: doc.id, data: doc.data() })
        })
        this.setState({
          users: usersList,
          loading: false,
        })
      })
  }

  componentWillUnmount() {
    this.props.firebase.users().off()
  }

  render() {
    const { users, loading } = this.state
    return (
      <div>
        <h1>Admin page</h1>
        {loading && <div>Loading ...</div>}
        <UserList users={users} />
      </div>
    )
  }
}

const UserList = ({ users }) => (
  <ul>
    {users.map(user => (
      <li key={user.id}>
        <span>
          <strong>ID:</strong> {user.id}
        </span>
        <span>
          <strong>E-Mail:</strong> {user.data.email}
        </span>
        <span>
          <strong>Username:</strong> {user.data.username}
        </span>
        <span>
          <strong>Space:</strong> {user.data.space}
        </span>
      </li>
    ))}
  </ul>
)

export default withFirebase(AdminPage)
