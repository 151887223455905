import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { Link } from 'baseui/link'
import { DisplaySmall } from 'baseui/typography'
import { SignUpLink } from './SignUp'
import { PasswordResetLink } from './PasswordReset'
import { withFirebase } from '../components/Firebase'
import InputEmail from '../components/InputEmail'
import InputPassword from '../components/InputPassword'

import * as ROUTES from '../constants/routes'
const SignInPage = () => (
  <Block
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Block flex="1" maxWidth="400px" marginTop="scale1200">
      <DisplaySmall>Sign In</DisplaySmall>
      <Block marginTop="scale800">
        <SignInForm />
        <Block marginTop="scale800"></Block>
        <PasswordResetLink />
        <SignUpLink />
      </Block>
    </Block>
  </Block>
)
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}
class SignInFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }
  onSubmit = event => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.ACCOUNT)
      })
      .catch(error => {
        this.setState({ error })
      })
    event.preventDefault()
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  render() {
    const { email, password, error } = this.state
    const isInvalid = password === '' || email === ''
    return (
      <form onSubmit={this.onSubmit}>
        <InputEmail
          label="Email"
          name="email"
          value={email}
          onChange={this.onChange}
          placeholder="Email Address"
        />
        <InputPassword
          label="Password"
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <Button disabled={isInvalid} type="submit">
          Sign In
        </Button>
        {error && <p>{error.message}</p>}
      </form>
    )
  }
}
const SignInForm = compose(withRouter, withFirebase)(SignInFormBase)
export default SignInPage
export { SignInForm }
