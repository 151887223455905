import * as React from 'react'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'
import Check from 'baseui/icon/check';
import { DisplayLarge, ParagraphMedium } from 'baseui/typography'
import {Grid, Cell, ALIGNMENT} from 'baseui/layout-grid';

function Landing() {
  const [css, theme] = useStyletron()
  return (
    <Block marginLeft={['scale500', 'scale800']} marginRight={['scale800', 'scale800']} >
      <Block marginTop="scale1600" display="flex" justifyContent="center">
          <DisplayLarge>Tell stories with data</DisplayLarge>
      </Block>
      <Block marginTop="scale1600" display="flex" justifyContent="center">
        <Block width='100%' maxWidth={theme.breakpoints.medium + 'px'}>
        <Grid align={ALIGNMENT.center} width="100%">
          <Cell span={1}><Check className={css({color: theme.colors.warning})} size={36}/></Cell>
          <Cell span={[3,7,11]}>
            <ParagraphMedium>Create your story in a local notebook or online with <a href="https://colab.research.google.com/">Google Colab</a> or <a href="https://notebooks.azure.com/">Azure notebooks</a></ParagraphMedium>
          </Cell>
          </Grid>
          <Grid align={ALIGNMENT.center} width="100%">
          <Cell span={1}><Check className={css({color: theme.colors.warning})} size={36}/></Cell>
          <Cell span={[3,7,11]}><ParagraphMedium>Publish and share your story</ParagraphMedium></Cell>
          </Grid>
        </Block>
        </Block>
    </Block>
  )
}
 
export default Landing
