import * as React from 'react'
import axios from 'axios'
import { Block } from 'baseui/block'
import { StatefulInput } from 'baseui/input'
import { Search as SearchComponent } from '../components/Search'
import { Hits } from '../components/Hits'

const server = process.env.REACT_APP_DEETLY_API

function Search() {
  const [query, setQuery] = React.useState('')
  const [hits, setHits] = React.useState()

  React.useEffect(() => {
    search(query)
  }, [query])

  const search = value => {
    setQuery(value)
    axios
      .get(server)
      .then(function(response) {
        setHits(response.data.hits)
      })
      .catch(function(error) {
        // handle error
        console.log(error)
      })
  }


  return (
    <>
      <Block margin="scale800" marginTop="scale1600" marginbottom="scale1600">
        <StatefulInput
          overrides={{ After: SearchComponent }}
          placeholder="Search examples"
          onChange={e => search(e.target.value)}
        />
        <Block marginBottom="scale800"></Block>
        <Block>
          <Hits hits={hits}></Hits>
        </Block>
      </Block>
    </>
  )
}

export default Search
