import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../components/Firebase'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { DisplaySmall, ParagraphSmall } from 'baseui/typography'
import InputEmail from '../components/InputEmail'
import * as ROUTES from '../constants/routes'
const PasswordResetPage = () => (
  <Block
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Block flex="1" maxWidth="400px" marginTop="scale1200">
      <DisplaySmall>Reset password</DisplaySmall>
      <Block marginTop="scale800">
        <PasswordResetForm />
      </Block>
    </Block>
  </Block>
)

const INITIAL_STATE = {
  email: '',
  error: null,
}
class PasswordResetFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }
  onSubmit = event => {
    const { email } = this.state
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        this.setState({ error })
      })
    event.preventDefault()
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  render() {
    const { email, error } = this.state
    const isInvalid = email === ''
    return (
      <form onSubmit={this.onSubmit}>
        <Block maxWidth="400px">
          <InputEmail
            label="Email"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            placeholder="Email Address"
          />
          <Button disabled={isInvalid} type="submit">
            Reset password
          </Button>
          {error && <p>{error.message}</p>}
        </Block>
      </form>
    )
  }
}
const PasswordResetLink = () => (
  <ParagraphSmall>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot your password?</Link>
  </ParagraphSmall>
)
export default PasswordResetPage
const PasswordResetForm = withFirebase(PasswordResetFormBase)
export { PasswordResetForm, PasswordResetLink }
