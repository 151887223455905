import * as React from 'react'
import { compose } from 'recompose'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { DisplaySmall, LabelSmall} from 'baseui/typography'
import {withFirebase} from '../components/Firebase'

import InputText from '../components/InputText'
import { AuthUserContext, withAuthorization } from '../components/Session'

const Space = (props) => {

  const [space, setSpace] = React.useState('')
  const [token, setToken] = React.useState(null)

  const onClick = (user) => {
    let userRef = props.firebase.db.collection('users').doc(user.id);
    let getDoc = userRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          console.log('Document data:', doc);
          setToken('1234')
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
  }
  
  const onChange = () => {
    console.log("on change")
  }
  
  const SpaceForm = ({user}) => {
    return (
      <Block>
        <LabelSmall>Account</LabelSmall>
        <LabelSmall>{user.email}</LabelSmall>
      <InputText
        label="Space name"
        name="space"
        value={space}
        onChange={onChange}
        placeholder="Space name / identifier"
      />
      <Button onClick={() => onClick(user)}>
        Create token
      </Button>
      <Block>
        {token}
      </Block>
      </Block>
    )
  }

  return (
    <AuthUserContext.Consumer>
    {authUser => (
        <Block
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Block flex="1" maxWidth="400px" marginTop="scale1200">
          <DisplaySmall>Add a new space to your account</DisplaySmall>
          <Block marginTop="scale800">
            <SpaceForm user={authUser} />
          </Block>
        </Block>
      </Block>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser
export default compose(withAuthorization(condition), withFirebase)(Space)
//export default withAuthorization(condition)(Space)
