import React from 'react'
import { Link, useParams } from 'react-router-dom'
import MD from 'react-markdown'

import { Block } from 'baseui/block'
import { Paragraph1, LabelSmall } from 'baseui/typography'

import { useSinglePost } from '../hooks'
import { formatDate } from '../components/utils'

export default function SinglePost() {
  const { id } = useParams()
  const [post, isLoading] = useSinglePost(id)

  const renderPost = () => {
    if (isLoading) return <p>Loading...</p>

    return (
      <Block>
        <Block>{post.title}</Block>
        <LabelSmall>{post.date}</LabelSmall>
        <Paragraph1>{post.description}</Paragraph1>
        <img src={post.heroImage.fields.file.url} alt={post.title} />
        <Paragraph1>
          <MD source={post.body} />
        </Paragraph1>
      </Block>
    )
  }

  return (
    <Block>
      <Link to="/blog">{'< Back'}</Link>
      {renderPost()}
    </Block>
  )
}
