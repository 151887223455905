import * as React from 'react'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { PasswordResetForm } from '../PasswordReset'
import PasswordChangeForm from '../../components/PasswordChange'
import { compose } from 'recompose'
import { withFirebase } from '../../components/Firebase'
import { withRouter } from 'react-router-dom'
import { AuthUserContext, withAuthorization } from '../../components/Session'

import * as ROUTES from '../../constants/routes'

const AccountPage = (props) => {
  const [tokens, setTokens] = React.useState([])

  const getTokens = () => {
    const tokensList = tokens.map((token, index) => (
      <Block key={`token_${index}`}>
        <Block>{token}</Block>
      </Block>
    ))
    return (
      <Block marginBottom="scale800">
        Tokens:
        {tokensList}
      </Block>
    )
  }

  const createSpace = () => {
    props.history.push(ROUTES.SPACE)
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <Block>
          <Block>
            <h1>Account: {authUser.email}</h1>
            <Button onClick={createSpace}>New space</Button>
            <Block>{getTokens()}</Block>
            <Block>
              <PasswordResetForm />
            </Block>
            <Block margin={'20px'}>
              <PasswordChangeForm />
            </Block>
          </Block>
          <Block>{JSON.stringify(authUser)}</Block>
        </Block>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser

export default compose(withAuthorization(condition), withFirebase, withRouter)(AccountPage)
