import * as React from 'react'
import { useHistory } from 'react-router-dom';
import {useStyletron} from 'baseui';
import {Layer} from 'baseui/layer';
import {
  ChevronDown,
  Search,
  Overflow as UserIcon,
  Upload as Icon,
} from 'baseui/icon';
import {Unstable_AppNavBar as AppNavBar} from 'baseui/app-nav-bar';

import SignOut from './SignOut'
import * as ROUTES from '../constants/routes'

function renderItem(item) {
  return item.label;
}

const MAIN_NAV = (authUser) => {
  const items = []
  items.push(
    {
      icon: Search,
      active: false,
      item: {label: 'Examples', route: ROUTES.SEARCH},
      mapItemToNode: renderItem,
      mapItemToString: renderItem,
    }
  )
 
  if (!authUser) {
    items.push(
      {
        icon: ChevronDown,
        active: false,
        item: {label: 'Sign up', route: ROUTES.SIGN_UP},
        mapItemToNode: renderItem,
        mapItemToString: renderItem,
      }
    )
    items.push(
      {
        icon: ChevronDown,
        active: false,
        item: {label: 'Sign in', route: ROUTES.SIGN_IN},
        mapItemToNode: renderItem,
        mapItemToString: renderItem,
      }
    )

  }

  return items
}

const USER_NAV = [
  {
    icon: UserIcon,
    item: {label: 'Account item1'},
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  }
];

export const HeaderBar = ({ authUser }) => {
  let history = useHistory()
  const [css] = useStyletron()
  const [selected, setSelected] = React.useState(null)

  const onNavItemSelect = (item) => {
    console.log(item)
    setSelected(item)
    history.push(item.item.route)
  }

  let User = {
    userNav: {USER_NAV},
    username: "Ola Normann",
    usernameSubtitle: "Oslo",
    userImgUrl: "",
  }

  return (
    <React.Fragment>
      <Layer>
        <div
          className={css({
            boxSizing: 'border-box',
            width: '100vw',
            position: 'fixed',
            top: '0',
            left: '0',
          })}
        >
          <AppNavBar
            appDisplayName="deetly"
            mainNav={MAIN_NAV(authUser)}
            onNavItemSelect={({item}) => {
              onNavItemSelect(item)
            }}
            userNav={[]}
          />
        </div>
      </Layer>
    </React.Fragment>
  )
}


export default HeaderBar
