import * as React from 'react'
import { Block } from 'baseui/block'

import { withAuthorization } from '../components/Session'

function Home() {
  return (
    <React.Fragment>
      <Block margin="scale1000">Home page</Block>
      <p>The Home Page is accessible by every signed in user.</p>
    </React.Fragment>
  )
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Home)
