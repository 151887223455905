const client = require('contentful').createClient({
  space: 'wihjzkux838l',
  accessToken: '9c5G2tM8g1VLsITDWBRBJ25IzVjaTdhj241lN4YjDnw',
})

const getBlogPosts = () =>
  client
    .getEntries({ content_type: 'blogPost' })
    .then(response => response.items)

const getSinglePost = slug =>
  client
    .getEntries({
      'fields.slug': slug,
      content_type: 'blogPost',
    })
    .then(response => response.items)

export { getBlogPosts, getSinglePost }
