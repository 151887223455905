import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyCiQbNdaj_8eRnAKIHQz4ElSjDbsfH-soY',
  authDomain: 'deetly-admin.firebaseapp.com',
  databaseURL: 'https://deetly-admin.firebaseio.com',
  projectId: 'deetly-admin',
  storageBucket: 'deetly-admin.appspot.com',
  messagingSenderId: '146727197501',
  appId: '1:146727197501:web:4090f0d0c6df5ef262c354',
  measurementId: 'G-33DJZBVF7R',
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.firestore()
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  // *** User API ***
  user = uid => this.db.collection('users').get(uid)
  users = () => this.db.collection('users')

  db = this.db
}

export default Firebase
